<template>
  <div id="interpretEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1">
      <h3 class="text-center">Interpret</h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleSelect1">Vyberte ročník:</label>
            <select class="form-control" v-model="interpret.periodId" id="exampleSelect1">
              <option v-for="period in periods" v-bind:key="period.id" v-bind:value="period.id">
                {{ period.name.cs }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="exampleSelect1">Vyberte žánry (multiselect):</label>
            <multiselect
                v-model="selectedGenres"
                :options="genres"
                placeholder="vyberte žánry"
                track-by="id"
                :multiple="true"
                :custom-label="getName"
                value="id"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <p>Fotografii vkládajte při poměru stran 4:3</p>
            <img class="img-fluid" width="50%" :src="image"/>
            <input type="file" class="form-control-file" accept="image/jpeg" @change="selectImage"
                   aria-describedby="fileHelp">
            <small id="fileHelp" class="form-text text-muted"></small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <h4 class="text-center">Čestina</h4>
          <form>
            <div class="form-group">
              <label for="formNameCz">Název interpreta:</label>
              <input type="text" class="form-control" id="formNameCz" v-model="interpret.name.cs"
                     placeholder="Název interpreta">
            </div>
            <div class="form-group">
              <label for="TextareaCz">Popis:</label>
              <textarea class="form-control" id="TextareaCz" v-model="interpret.description.cs" rows="3"></textarea>
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <form>
            <div class="form-group">
              <label for="formNameEn">Název interpreta:</label>
              <input type="text" class="form-control" id="formNameEn" v-model="interpret.name.en"
                     placeholder="Název interpreta">
            </div>
            <div class="form-group">
              <label for="TextareaEn">Popis:</label>
              <textarea class="form-control" id="TextareaEn" v-model="interpret.description.en" rows="3"></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-check form-switch text-center pt-lg-4">
        <input id="visible" class="form-check-input" type="checkbox" v-model="interpret.visible">
        <label class="form-check-label" for="visible">Viditelný</label>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Interpreta"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import Multiselect from 'vue-multiselect'
import config from '@/config'
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";

export default {
  name: "adminScheduleAddInterprets",
  components: {
    'app-menu': AdminMenu,
    'multiselect': Multiselect,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      interpret: {name: {}, description: {}, genreIds: []},
      periods: [],
      genres: [],
      selectedGenres: [],
      image: "",
      error: null
    }
  },
  created() {
    axios
        .get("/period/list")
        .then((success) => {
          this.periods = success.data
        }).catch((error) => this.error = error)
    axios
        .get("/genre/list")
        .then((success) => {
          this.genres = success.data
          if (this.$route.params.id != null) {
            this.load(this.$route.params.id)
          }
        }).catch((error) => this.error = error)

  },
  methods: {
    create: function () {
      this.interpret.genreIds = this.selectedGenres.map(it => it.id)
      axios.post("/interpret", this.interpret).then((success) => {
        axios.post("/image", {name: success.data.id, type: "interprets", data: this.image}).then(() => {
          this.$router.push({path: "/admin/program/interpret"})
        }).catch((error) => this.error = error)
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/interpret", {params: {id: id}})
          .then((success) => {
            this.interpret = success.data;
            this.image = config.API_URL + "/image?type=interprets&name=" + id
            this.genres.forEach(genre => {
              if (this.interpret.genreIds.includes(genre.id)) {
                this.selectedGenres.push(genre);
              }
            })
          }).catch((error) => this.error = error)
    },
    selectImage: function (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = e => {
        this.image = e.target.result
      }
    },
    getName: function (genre) {
      return genre.name.cs;
    }
  }
}
</script>

<style scoped>
#interpretEdit {
  padding-bottom: 3em;
  background-color: white;
  min-height: 100vh;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
